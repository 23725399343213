/** @jsx jsx */

import { jsx, Heading, Box } from "theme-ui"
import React, { Fragment } from "react"
import { graphql } from "gatsby"
import {
  ArticleLanguageSelector,
  ContentPage,
  ContentPageHeader,
  BigContentHeader,
  ContentMetadata,
  ContentMetadataItem,
  GenericArticle,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { Join } from "../data/list"
import { Link, LiteralLink } from "../components/nav"
import { FeaturedList, FeaturedResource, useLocalisedContent, useLocalisedContentViewer } from '../components/featured';
import SEO from '../components/seo';
import { useTranslation, Trans } from 'react-i18next';
import { useTextDirection, TextDirectionProvider } from '../i18n';

const Organisation: PageRoot<{ node: ContentNode }> = (args) => {
  const {
    data: { node },
    pageContext: { locale, locales },
    location: { pathname }
  } = args
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const { t } = useTranslation()
  const dir = useTextDirection(locale)

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={localisedContent.shortAbstract || localisedContent.abstract}
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <ContentPageHeader mono node={node}>
          <BigContentHeader {...localisedContent} mono content={node} />
        </ContentPageHeader>
        <ContentPage
          {...localisedContent}
          content={node}
          children={
            <Fragment>
              <GenericArticle
                {...localisedContent}
                themeColor={node.fields.themeColor}
              />
              <Fragment>
                {node.frontmatter.resources?.length > 0 && (
                  <Fragment>
                    <Heading variant='headings.3' sx={{ mb: 3, color: node.fields.themeColor }}>
                      <Trans>Resources</Trans>
                    </Heading>

                    <FeaturedList sx={{ mb: 7 }}>
                      {node.frontmatter.resources?.map(resource => (
                        <FeaturedResource node={resource} themeColor={node.fields.themeColor} />
                      ))}
                      <div sx={{ borderTop: "2px solid black" }} />
                    </FeaturedList>
                  </Fragment>
                )}
              </Fragment>
            </Fragment>
          }
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem label={t(`Authors`)}>
                  <Join
                    value={node.frontmatter.authors.map(a => a.frontmatter.title)}
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={<span>{localisedContent.translators?.length > 1 ? t(`Translators`) : t(`Translator`)}</span>}
                >
                  <Join
                    value={localisedContent.translators?.map(a => a.frontmatter.title)}
                  />
                </ContentMetadataItem>
              )}
              {node.frontmatter.facebook && (
                <ContentMetadataItem label="Facebook">
                  <Link to={`https://facebook.com/${node.frontmatter.facebook}`}>
                    {node.frontmatter.facebook} ↗
              </Link>
                </ContentMetadataItem>
              )}
              {node.frontmatter.twitter && (
                <ContentMetadataItem label="Twitter">
                  <Link to={`https://twitter.com/${node.frontmatter.twitter}`}>
                    {node.frontmatter.twitter} ↗
              </Link>
                </ContentMetadataItem>
              )}
              {node.frontmatter.instagram && (
                <ContentMetadataItem label="Instagram">
                  <Link to={`https://instagram.com/${node.frontmatter.instagram}`}>
                    {node.frontmatter.instagram} ↗
              </Link>
                </ContentMetadataItem>
              )}
              {node.frontmatter.website && (
                <ContentMetadataItem label="Website">
                  <LiteralLink to={node.frontmatter.website} />
                </ContentMetadataItem>
              )}
            </ContentMetadata>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query Organisation($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }
  }
`

export default Organisation
